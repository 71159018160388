import React from 'react';
// @mui
import {Grid, Button, CardHeader, Divider, CardContent, Select, MenuItem} from '@mui/material';
import FileUpload from "react-mui-fileuploader";
import Swal from 'sweetalert2';
import CloseIcon from "@mui/icons-material/Close";

import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage, showLoader } from '../../../auxiliares/funciones';
import CONFIG from '../../../CONFIG.json';
import Iconify from "../../../componentes/Iconify";
import RTextField from "../../../componentes/forms/inputs/RTextField";

// ----------------------------------------------------------------------

export default function EstadosForm({ cierraModal }) {

  const [fileKey, setFileKey] = React.useState(getUniqueKey());
  const [filesForm, setFiles] = React.useState([]);
  const [anyo, setAnyo] = React.useState('');
  const usuario = workWithLocalStorage('get','usuario',null);

  const handleFilesChange = (files) => {
    // Update chosen files
    setFiles([...files]);
  };

  const uploadFiles = () =>{
    // Create a form and post it to server
    const formData = new FormData();
    filesForm.forEach((file) => formData.append("files", file,file.name));
    if(anyo === ''){
        Swal.fire({
                title: 'Debes seleccionar un año',
                buttons: [{label: 'Ok'}]
            })
    }else if (!isEmpty(filesForm) && filesForm.length === 1) {
            // eslint-disable-next-line react/prop-types
            showLoader(true);
            const body = {};
            body.anyo = anyo
            // body.idCertificado = 1
            const bodyJson = JSON.stringify(buildBody(usuario.token, body))
            formData.append("str", bodyJson);
            fetch(`${CONFIG.api}descarga/subir`, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then((result) => {
                    let msg = 'Documento subido!';
                    if (result.error === 0) {
                        cierraModal()
                    } else {
                        msg = 'Ha ocurrido un error. Hablad con Rubén';
                    }
                    showLoader(false);
                    Swal.fire({
                        title: msg,
                        buttons: [{label: 'Ok'}]
                    })
                })
        } else {
            Swal.fire({
                title: 'Debes seleccionar un fichero',
                buttons: [{label: 'Ok'}]
            })
        }
  }

  const actualizaCampo = (input) =>{
        const valor = input.target.value
        setAnyo(valor);
    }

  return (<>
      <CardHeader title={"Subir Fichero"} action={<>
            <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
            onClick={()=>uploadFiles()}>
                Subir
            </Button>
            <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
        </>}/>
        <Divider style={{ margin: '15px'}} />
        <CardContent>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                <RTextField type="number" name="anyo" label="Año*" actualizaCampo={actualizaCampo} value={anyo} md={12} />
                <Grid item md={12}>
                    <FileUpload key={fileKey} title="" header="[Arrastra el documento que quieras subir]" leftLabel="o" rightLabel="para seleccionarlo"
                        allowedExtensions={['vnd.openxmlformats-officedocument.spreadsheetml.sheet']} maxUploadFiles={1}
                        buttonLabel="Pulsa aquí" buttonRemoveLabel="Borrar" onFilesChange={handleFilesChange} onContextReady={(_context) => {}}/>
                </Grid>
            </Grid>
        </CardContent>
      </>
  );
}
